import instance from "../../api";
import { handleError } from "../../api/helpers";

export const getConversionData = (params) => {
  return instance(true)
    .post(`/crm/reports/conversion/`, params)
    .then((res) => {
      return res?.data;
    });
};

export const getCallCenterManagers = () => {
  return instance(true)
    .get(`/crm/call_center/`)
    .then((res) => {
      return res?.data;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};

export const getRequestTypes = () => {
  return instance(true)
    .get(`/crm/request_types/`)
    .then((res) => {
      if (!res?.data?.length) return [];
      return [{ request_type_id: null, request_type_name: "Все" }, ...res.data];
    })
    .catch((err) => {
      return handleError(err);
    });
};

export const contractSum = (params) => {
  return instance(true)
    .post(`/crm/reports/contract_sum/`, params)
    .then((res) => {
      return res?.data;
    });
};
export const getDiscountList = (params) => {
  return instance(true)
    .post(`/crm/reports/sales/`, params)
    .then((res) => {
      return res?.data;
    });
};
export const ccTaskReport = (params) => {
  return instance(true)
    .post(`/crm/reports/cc_task_report/`, params)
    .then((res) => {
      return res?.data;
    });
};
export const getTaskTypeCallCenter = () => {
  return instance(true)
    .get(`/crm/task_type_call_center/`)
    .then((res) => {
      return res?.data;
    })
    .catch((err, data) => {
      return handleError(err);
    });
};
